import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import SectionHeader from './SectionHeader'

export default function SupportList() {
  const { spacing, palette } = useTheme()

  return (
    <div style={{ background: palette.background.paper }}>
      <SectionHeader primary='対応アプリ / デバイス' />
      <div style={{ padding: spacing(6) }}>
        <li>Runtripアプリ</li>
        <li>GARMIN</li>
        <li>Polar</li>
        <li>Fitbit</li>
        <li>Suunto</li>
        <li>Apple Watch</li>
      </div>
    </div>
  )
}
