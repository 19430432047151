import React from 'react'

export default function Donuts({ color }) {
  return (
    <svg width='95' height='60' viewBox="0 0 338.65 313.36" fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M323.28,276.64A79.2,79.2,0,1,1,301.94,166.7,79.18,79.18,0,0,1,323.28,276.64Zm-80.52-22.25a26.6,26.6,0,1,0-7.17-36.93A26.6,26.6,0,0,0,242.76,254.39Z' fill={`#fff`} />
      <circle cx='257.64' cy='232.34' r='26.61' fill={`none`} stroke={`#0e693a`} strokeMiterlimit={10} strokeWidth={`3.61841023455096px`} />
      <path d='M213.34,298a79.19,79.19,0,1,1,109.94-21.35A79.21,79.21,0,0,1,213.34,298Z' fill={`none`} stroke={`#0e693a`} strokeMiterlimit={10} strokeWidth={`3.61841023455096px`} />
      <path d='M229.63,291.56a65.74,65.74,0,0,1-9.18-5.31' fill={`none`} stroke={`#0e693a`} strokeLinecap={`round`} strokeMiterlimit={10} strokeWidth={`2.41227348970064px`} />
      <path d='M320.45,213.76A65.52,65.52,0,0,1,238.6,295' fill={`none`} stroke={`#0e693a`} strokeLinecap={`round`} strokeMiterlimit={10} strokeWidth={`2.41227348970064px`} />
      <path d='M201.39,201.05a65.57,65.57,0,0,1,74.94-32.68' fill={`none`} stroke={`#0e693a`} strokeLinecap={`round`} strokeMiterlimit={10} strokeWidth={`2.41227348970064px`} />
      <path d='M100.73,53.71A115.43,115.43,0,1,1,4.38,185.45,115.43,115.43,0,0,1,100.73,53.71Zm56,108.1a38.78,38.78,0,1,0-32.37,44.27A38.79,38.79,0,0,0,156.75,161.81Z' fill={'#0e693a'} />
      <circle cx='118.43' cy='167.76' r='38.78' fill={`none`} stroke={`#0e693a`} strokeMiterlimit={10} strokeWidth={`6px`} />
      <path d='M232.5,150.06A115.42,115.42,0,1,1,100.73,53.71,115.44,115.44,0,0,1,232.5,150.06Z' fill={`none`} stroke={`#0e693a`} strokeMiterlimit={10} strokeWidth={`6px`} />
      <line x1='108.28' y1='69.36' x2='110.72' y2='79.74' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='149.18' y1='74.87' x2='144.3' y2='81.58' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='37.28' y1='115.46' x2='46' y2='104.76' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='37.28' y1='164.5' x2='48.43' y2='159.09' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='31.94' y1='202.43' x2='39.27' y2='190.84' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='78.95' y1='209.16' x2='65.4' y2='209.85' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='71.62' y1='237.85' x2='58.06' y2='238.54' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='97.26' y1='237.86' x2='102.75' y2='247.62' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='175.41' y1='199.41' x2='185.17' y2='204.91' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='141.21' y1='250.68' x2='150.98' y2='256.18' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='199.22' y1='183.54' x2='212.45' y2='183.64' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='84.46' y1='100.49' x2='93.62' y2='107.82' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='55.56' y1='134.38' x2='66.14' y2='137.73' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='125.34' y1='230.53' x2='135.59' y2='222.72' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='165.02' y1='229.32' x2='176.85' y2='231.55' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='180.91' y1='144.47' x2='192.03' y2='138.5' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <line x1='155.28' y1='113.94' x2='154.67' y2='104.17' fill={`#fff`} stroke={`#fff`} strokeLinecap={`round`} strokeLinejoin={`round`} strokeWidth={`5px`} />
      <ellipse cx='221.92' cy='47.03' rx='48.06' ry='47.03' fill={`#fff`} />
      <circle cx='170.04' cy='63.67' r='19.25' fill={`#fff`} />
      <circle cx='169.35' cy='45.54' r='16.24' fill={`#fff`} />
      <circle cx='174.64' cy='79.32' r='16.24' fill={`#fff`} />
      <circle cx='186.44' cy='91.53' r='16.24' fill={`#fff`} />
      <circle cx='198.65' cy='99.26' r='16.24' fill={`#fff`} />
      <circle cx='213.71' cy='99.67' r='16.24' fill={`#fff`} />
      <circle cx='226.77' cy='97.27' r='16.24' fill={`#fff`} />
      <polygon points='191.66 51.06 206.94 3.83 192.45 0.54 191.66 51.06' fill={`#0e693a`} stroke={`#0e693a`} strokeLinecap={`round`} strokeLinejoin={`round`}/>
      <polygon points='213.88 58.47 255.09 30.69 243.28 17.49 213.88 58.47' fill={`#0e693a`} stroke={`#0e693a`} strokeLinecap={`round`} strokeLinejoin={`round`} />
      <polygon points='225.46 81.62 269.91 64.03 272.23 79.31 225.46 81.62' fill={`#0e693a`} stroke={`#0e693a`} strokeLinecap={`round`} strokeLinejoin={`round`} />
    </svg>
  )
}
